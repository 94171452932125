<template>
  <div :class="['action-item', {'active': info.checked && info.selected}]">
    <div class="action-item__select" @click="$emit('select')" v-if="!isSimpleView">
      <i class="icon fa fa-ban" aria-hidden="true" v-if="!info.selected"></i>
      <i class="icon fa fa-check-circle selected" aria-hidden="true" v-else-if="info.checked"></i>
      <i class="icon fa fa-circle-thin" aria-hidden="true" v-else></i>
      <div class="action-item__info">
        <h4>{{itemName}}</h4>
        <p v-html="$t('selection.tipCount', {count: info.selected + '/' + info.total})"></p>
      </div>
    </div>
    <router-link :to="contentLink" class="action-item__entry" :class="{'empty': !info.photos.length}">
      <template v-if="info.photos.length">
        <div class="image" v-for="(image, index) in info.photos" :key="index"
             v-lazy:background-image="getImageUrl(image, 320)"></div>
      </template>
      <template v-else>
        <div class="text-ellipsis" style="line-height: 72px;" :title="info.content">{{info.content}}</div>
      </template>
      <div class="pages-name" v-if="isSimpleView">{{itemName}}</div>
      <i class="icon fa fa-angle-right" aria-hidden="true"></i>
    </router-link>
  </div>
</template>

<script>
import { getImageUrl } from 'src/_utils/'
import { mapGetters } from 'vuex'
import config from '../../app.config'

export default {
  name: '',
  props: {
    itemType: {
      type: String,
      default: 'facebook'
    },
    isSimpleView: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: function () {
        return {
          // month: 02,
          // time: 201202,
          content: '图片+视频+文字',
          checked: false,
          scid: '',
          selected: 123,
          total: 2,
          photos: []
        }
      }
    }
  },
  computed: {
    ...mapGetters(['language']),
    itemName () {
      // 非 facebook/patch 类型直接返回名称
      if (!['facebook', 'patch'].includes(this.itemType)) {
        return this.info.name
      }

      // 转换月份数字
      const monthNum = Number(this.info.month)

      // 处理非数字月份
      if (isNaN(monthNum)) {
        return this.info.month
      }

      // 英文返回月份名称，中文返回数字月份
      if (this.language === 'en') {
        return config.months[monthNum - 1] || ''
      }

      return `${monthNum}月`
    },
    contentLink () {
      if (this.isSimpleView) {
        return {
          name: 'pages',
          query: Object.assign(
            {},
            this.$route.query,
            {
              scid: this.info.scid,
              pid: this.info.pageId || this.info.insId
            }
          )
        }
      }
      return {
        name: 'content',
        query: Object.assign({}, this.$route.query, { scid: this.info.scid })
      }
    }
  },
  methods: {
    getImageUrl
  }
}
</script>

<style lang="scss">
.action-item + .action-item {
  margin-top: 1rem;
}

.action-item {
  border-radius: 4px;
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  border: 1px solid #DFDFDF;
  width: 100%;
  cursor: pointer;

  &.active {
    background-color: #fff;

    .action-item__select i {
      color: $el-color-primary;
    }
  }

  i.icon {
    font-size: 2rem;
    color: #d9d9d9;
  }

  .action-item__select {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0 10px 16px;
  }

  .action-item__info {
    margin-left: 1rem;

    h4 {
      font-size: 1rem;
      margin: 0;
      margin-bottom: 4px;
    }

    p {
      font-size: 15px;
      color: #999;
      margin: 0;
    }
  }

  .pages-name {
    padding-left: 20px;
    flex: 1;
  }

  .action-item__entry {
    padding: 10px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    gap: 8px;

    &.empty {
      overflow: hidden;
    }

    .image {
      height: 72px;
      width: 72px;
      border-radius: 4px;
      background-color: #eee;
      border: 1px solid #eee;
      background: {
        size: cover;
        repeat: no-repeat;
        position: 50%;
      }

      &[lazy=loading] {
        background-size: 48px;
      }
    }

    i.icon {
      margin-left: 10px;
    }
  }
}
</style>
